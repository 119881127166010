import TermsOfServicesMeta from "../metas/terms_of_services";

const TermsOfServices = () => {
  return (
    <>
      <TermsOfServicesMeta />
      <main id="main">
        <div className="breadcrumbs" data-aos="fade-in">
          <div className="container">
            <h2>Terms and Conditions</h2>
            <p>Read the terms and conditions for using mathpeedup.</p>
          </div>
        </div>
        <section id="practice" className="practice">
          <div className="container" data-aos="fade-up">
            <div className="row mt-5">
              <div className="col-lg-2"></div>
              <div className="col-lg-8">
                <h1>Terms and Services of MathSpeedUp</h1>
                <p>
                  <strong>Effective Date:</strong> [30-Sep-2024]
                </p>
                <p>
                  Welcome to MathSpeedUp! By accessing or using MathSpeedUp, you
                  agree to be bound by these Terms and Services ("Terms").
                  Please read these Terms carefully.
                </p>
                <ol>
                  <li>
                    Acceptance of Terms By creating an account, accessing, or
                    using any part of the MathSpeedUp application, you indicate
                    your acceptance of these Terms. If you do not agree to these
                    Terms, please do not use the application.
                  </li>
                  <li>
                    Description of Service MathSpeedUp is an application
                    designed to enhance your math calculation speed through
                    interactive games and exercises. The app also provides login
                    and sign-up features to personalize your experience, save
                    your progress, and share reports about your game
                    performance.
                  </li>
                  <li>
                    User Eligibility To use MathSpeedUp, you must be at least
                    [13/16/18] years old, depending on applicable laws in your
                    jurisdiction. By using MathSpeedUp, you confirm that you are
                    eligible under these terms.
                  </li>
                  <li>
                    Account Registration You must create an account to access
                    some features of MathSpeedUp. During the registration
                    process, you agree to provide accurate information and keep
                    your account information up to date. You are solely
                    responsible for the security and confidentiality of your
                    login credentials and for any activity on your account.
                  </li>
                  <li>
                    User Conduct By using MathSpeedUp, you agree not to:
                    <ul>
                      <li>Use the application for any unlawful purpose.</li>
                      <li>Interfere with or disrupt the app or its servers.</li>
                      <li>
                        Attempt to hack or compromise the security of the
                        application.
                      </li>
                      <li>
                        Post or distribute content that is abusive, offensive,
                        or otherwise objectionable. MathSpeedUp reserves the
                        right to terminate your account if you violate any of
                        these provisions.
                      </li>
                    </ul>
                  </li>
                  <li>
                    Ownership and Intellectual Property All content provided by
                    MathSpeedUp, including games, software, graphics, text, and
                    trademarks, is the property of MathSpeedUp or its licensors.
                    You are granted a non-exclusive, non-transferable, limited
                    license to use MathSpeedUp solely for personal,
                    non-commercial use.
                  </li>
                  <li>
                    Use of Personal Information MathSpeedUp collects personal
                    data during the registration process, including email
                    addresses, usernames, and usage data, to improve user
                    experience and offer personalized reports. By using
                    MathSpeedUp, you agree to our collection and processing of
                    this information. Please refer to our Privacy Policy for
                    further details on how we use and protect your data.
                  </li>
                  <li>
                    Payment and Subscription MathSpeedUp may offer certain
                    features or content for a fee ("Paid Features"). All
                    payments are final, and no refunds will be issued, except as
                    required by applicable law. We may offer subscription-based
                    services that automatically renew unless you cancel before
                    the renewal date.
                  </li>
                  <li>
                    Game Data and Reporting MathSpeedUp stores game performance
                    data to generate reports for users to track progress. You
                    acknowledge that MathSpeedUp is not responsible for any data
                    loss or inaccuracies that may occur.
                  </li>
                  <li>
                    Availability and Modifications MathSpeedUp is provided on an
                    "as is" basis, and we do not guarantee uninterrupted service
                    or error-free operation. We reserve the right to modify or
                    discontinue any part of MathSpeedUp at any time without
                    notice.
                  </li>
                  <li>
                    Disclaimer of Warranties MathSpeedUp is provided "as is"
                    without any warranties, whether express or implied. We do
                    not guarantee that the application will meet your needs, be
                    available at all times, or be free from errors or defects.
                  </li>
                  <li>
                    Limitation of Liability To the maximum extent permitted by
                    law, MathSpeedUp shall not be liable for any damages,
                    including direct, indirect, incidental, consequential, or
                    punitive damages, arising from your use of or inability to
                    use the application.
                  </li>
                  <li>
                    Disclaimer of Warranties MathSpeedUp is provided "as is"
                    without any warranties, whether express or implied. We do
                    not guarantee that the application will meet your needs, be
                    available at all times, or be free from errors or defects.
                  </li>
                  <li>
                    Limitation of Liability To the maximum extent permitted by
                    law, MathSpeedUp shall not be liable for any damages,
                    including direct, indirect, incidental, consequential, or
                    punitive damages, arising from your use of or inability to
                    use the application.
                  </li>
                  <li>
                    Indemnification You agree to indemnify and hold MathSpeedUp,
                    its affiliates, officers, employees, and agents harmless
                    from any claim, demand, or damage, including reasonable
                    attorney's fees, arising out of your use of the application
                    or violation of these Terms.
                  </li>
                  <li>
                    Contact Us If you have questions about these Terms, please
                    contact us at <strong>manishprajapat94@gmail.com</strong>.
                  </li>
                </ol>
              </div>
            </div>
          </div>
        </section>
      </main>
    </>
  );
};

export default TermsOfServices;
