import React from "react";
import { Helmet } from "react-helmet";
const TermsOfServicesMeta = () => {
  return (
    <div>
      <Helmet>
        <title>Math Speed Boost - Terms and Services</title>
        <meta charSet="UTF-8" />
        <meta name="title" content="Math Speed Boost - Terms and Services" />
        <meta
          name="description"
          content="Read the terms and services for using mathpeedup."
        />
        <meta name="keywords" content="terms and services, rules , policy " />
        <meta
          property="article:published_time"
          content="2023-09-17T01:30:57+00:00"
        />
      </Helmet>
    </div>
  );
};
export default TermsOfServicesMeta;
