import React, { useEffect, useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCommentDots } from "@fortawesome/free-solid-svg-icons";
import GlobalChat from "./p2p/global_chat";
import { databaseKey } from "./common/database_key";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { sendNotification } from "./notification_ref";
import { fetchData, pushData } from "./firebase/firebase_methods";
import { fetchIpAddress } from "../components/common/fetch_ip";
import { trackButtonClick } from "../pages/event_track";
import { useLocation } from "react-router-dom";

function Footer() {
  const [isChatOpen, setIsChatOpen] = useState(false);
  const [userName, setUserName] = useState("");
  const [userIP, setUserIP] = useState(null);
  const [error, setError] = useState(null);
  const location = useLocation();

  useEffect(() => {
    const storedName = localStorage.getItem("userName") || "";
    setUserName(storedName);
    setIsChatOpen(window.innerWidth > 768);
  }, []);

  const showToastMessage = (message) => {
    toast.success(message, {
      position: toast.POSITION.TOP_RIGHT,
    });
  };

  useEffect(() => {
    const backtotop = document.querySelector(".back-to-top");

    if (backtotop) {
      const toggleBacktotop = () => {
        if (window.scrollY > 100) {
          backtotop.classList.add("active");
        } else {
          backtotop.classList.remove("active");
        }
      };

      window.addEventListener("load", toggleBacktotop);
      window.addEventListener("scroll", toggleBacktotop);

      // Cleanup the event listeners when the component unmounts
      return () => {
        window.removeEventListener("load", toggleBacktotop);
        window.removeEventListener("scroll", toggleBacktotop);
      };
    }
  }, []);

  const handleChatIconClick = (e) => {
    if (e) {
      e.preventDefault();
    }
    trackButtonClick("Chat Open");
    setIsChatOpen(!isChatOpen);
  };

  const handleSubscriber = async (e) => {
    trackButtonClick("Subscriber");

    const collectionName = databaseKey();
    e.preventDefault();
    const email = e.target.email.value;
    fetchIpAddress(setUserIP);

    if (validateEmail(email)) {
      const subRef = fetchData(`${collectionName}/subscriber`);
      let data = {
        email: email,
        userIP: userIP,
      };
      await pushData(subRef, data);
      showToastMessage("email submitted successfully!");
      await sendNotification(
        userName,
        `Thank you for subscribing, ${userName}!`
      );
      e.target.reset();
      setError(null);
    } else {
      setError("invalid email");
    }
  };

  const validateEmail = (email) => {
    const emailRegex = /^[a-z0-9][\w.]+@\w+?(\.\w+)+$/i;
    return emailRegex.test(email);
  };

  const isSignUpPage = location.pathname.includes("sign-up");

  return (
    <>
      <footer id="footer">
        <div className="footer-top">
          <div className="container">
            <div className="row">
              <div className="col-lg-3 col-md-6 footer-contact">
                <h3>MathSpeedUp</h3>
                <p>
                  Kanha Park<br></br>
                  Rau, Madhya Pradesh 453331<br></br>
                  India<br></br>
                  {/* <strong>Phone:</strong> +1 5589 55488 55 */}
                  <strong>Email:</strong> manishprajapat94@gmail.com
                </p>
              </div>

              <div className="col-lg-2 col-md-6 footer-links">
                <h4>Useful Links</h4>
                <ul>
                  <li>
                    <i className="bx bx-chevron-right"></i>{" "}
                    <a href="/" title="Home">
                      Home
                    </a>
                  </li>
                  <li>
                    <i className="bx bx-chevron-right"></i>{" "}
                    <a href="/about" title="About Us">
                      About us
                    </a>
                  </li>
                  <li>
                    <i className="bx bx-chevron-right"></i>{" "}
                    <a href="/contact" title="Contact">
                      Contact
                    </a>
                  </li>
                  <li>
                    <i className="bx bx-chevron-right"></i>{" "}
                    <a href="/terms-of-services" title="Terms Of Service">
                      Terms of service
                    </a>
                  </li>
                  <li>
                    <i className="bx bx-chevron-right"></i>{" "}
                    <a href="/policy" title="Privacy Policy">
                      Privacy policy
                    </a>
                  </li>
                  <li>
                    <i className="bx bx-chevron-right"></i>
                    <a href="/feedback" title="Feedback">
                      Feedback
                    </a>
                  </li>
                </ul>
              </div>

              <div className="col-lg-3 col-md-6 footer-links">
                <h4>Our Services</h4>
                <ul>
                  <li>
                    <i className="bx bx-chevron-right"></i>{" "}
                    <a href="/practice" title="Practice Area">
                      Practice Area
                    </a>
                  </li>
                  <li>
                    <i className="bx bx-chevron-right"></i>{" "}
                    <a href="/against_time" title="Against Time">
                      Against Time
                    </a>
                  </li>
                  <li>
                    <i className="bx bx-chevron-right"></i>{" "}
                    <a href="/games/memorygame" title="Memory Games">
                      Memory Games
                    </a>
                  </li>
                  <li>
                    <i className="bx bx-chevron-right"></i>{" "}
                    <a href="/readers" title="Readers Area">
                      Readers Area
                    </a>
                  </li>
                  <li>
                    <i className="bx bx-chevron-right"></i>{" "}
                    <a href="/player-vs-player" title="Player vs Player">
                      Player vs Player
                    </a>
                  </li>
                  <li>
                    <i className="bx bx-chevron-right"></i>
                    <a href="/quiz" title="Quiz">
                      Quiz
                    </a>
                  </li>
                  <li>
                    <i className="bx bx-chevron-right"></i>
                    <a href="/missing-number" title="Missing Number">
                      Missing Number
                    </a>
                  </li>
                  <li>
                    <i className="bx bx-chevron-right"></i>
                    <a href="/games/multiplication" title="Multiplication">
                      Multiplication
                    </a>
                  </li>
                  <li>
                    <i className="bx bx-chevron-right"></i>
                    <a href="/games/quoridor_game" title="Quoridor Game">
                      Quoridor Game
                    </a>
                  </li>
                  <li>
                    <i className="bx bx-chevron-right"></i>
                    <a
                      href="/games/arithmetic-memory-game"
                      title="Quoridor Game"
                    >
                      Arithmetic Memory Game
                    </a>
                  </li>
                </ul>
              </div>

              <div className="col-lg-4 col-md-6 footer-newsletter">
                <h4>Join Our Newsletter</h4>
                <p>Don't worry we wont send spam emails.</p>
                <form action="" method="post" onSubmit={handleSubscriber}>
                  <label htmlFor="email">Email : </label>
                  <input
                    type="email"
                    name="email"
                    id="email"
                    placeholder="Enter Your Email"
                    autoComplete="email"
                  />
                  <input type="submit" value="Subscribe" />
                </form>
                <p style={{ color: "red" }}>
                  {" "}
                  {error ? <>{error}</> : <>{""}</>}
                </p>
              </div>
            </div>
          </div>
        </div>

        <div className="container d-md-flex py-4 justify-content-center">
          <div className="text-center">
            <div className="copyright">
              &copy; Copyright{" "}
              <strong>
                <span>MathSpeedUp</span>
              </strong>
              . All Rights Reserved
            </div>
          </div>
          {/*
          <div className="social-links text-center text-md-right pt-3 pt-md-0">
            <a href="/" className="twitter" title='twitter' aria-label="twitter"><i className="bx bxl-twitter"></i></a>
            <a href="/" className="facebook" title='facebook' aria-label="facebook"><i className="bx bxl-facebook"></i></a>
            <a href="/" className="instagram" title='instagram' aria-label="instagram"><i className="bx bxl-instagram"></i></a>
            <a href="/" className="google-plus" title='google-plus' aria-label="skype" ><i className="bx bxl-skype"></i></a>
            <a href="/" className="linkedin" title='linkedin' aria-label="linkedin"><i className="bx bxl-linkedin"></i></a>
          </div>
          */}
        </div>
      </footer>
      {!isSignUpPage && (
        <>
          <div>
            {isChatOpen && (
              <GlobalChat userName={userName} onClose={handleChatIconClick} />
            )}
          </div>
          <a
            href="#"
            onClick={handleChatIconClick}
            title="Global Chat"
            className={`global-chat ${isChatOpen ? "hidden" : "closed"}`}
          >
            <FontAwesomeIcon icon={faCommentDots} style={{ margin: "10px" }} />
          </a>
        </>
      )}
      <a
        href="#"
        className="back-to-top d-flex align-items-center justify-content-center"
        title="Scroll to Top"
        aria-label="Scroll to Top"
      >
        <i className="bi bi-arrow-up-short"></i>
      </a>
    </>
  );
}

export default Footer;
