import React from "react";
import PracticeMetas from "../metas/practice";

const Policy = () => {
  return (
    <>
      <PracticeMetas />
      <main id="main">
        <div className="breadcrumbs" data-aos="fade-in">
          <div className="container">
            <h1>Policy</h1>
            <p>Your Privacy Matters: learn how we protect your information.</p>
          </div>
        </div>
        <section id="practice" className="practice">
          <div className="container" data-aos="fade-up">
            <div className="row mt-5">
              <div className="col-lg-2"></div>
              <div className="col-lg-8">
                <h1>Privacy Policy for MathSpeedUp.com</h1>
                <p>
                  <strong>Effective Date:</strong> [23-Aug-2023]
                </p>

                <p>
                  At MathSpeedUp.com, the privacy of our visitors is of utmost
                  importance to us. This Privacy Policy document outlines the
                  types of personal information that is received and collected
                  by MathSpeedUp.com and how it is used.
                </p>

                <h2>Information Collection and Use</h2>
                <p>
                  MathSpeedUp.com does not require you to provide any personal
                  information when visiting our website. However, certain
                  features on our website might ask for your voluntary
                  submission of personal information, such as your name, email
                  address, and other relevant information. This information is
                  collected solely for the purpose of improving user experience
                  and providing you with relevant content.
                </p>

                <h2>Log Files</h2>
                <p>
                  Like many other websites, MathSpeedUp.com makes use of log
                  files. The information inside the log files includes internet
                  protocol (IP) addresses, browser type, Internet Service
                  Provider (ISP), date/time stamp, referring/exit pages, and
                  possibly the number of clicks. This information is used to
                  analyze trends, administer the site, track user's movement
                  around the site, and gather demographic information. IP
                  addresses and other such information are not linked to any
                  information that is personally identifiable.
                </p>

                <h2>Cookies and Web Beacons</h2>
                <p>
                  MathSpeedUp.com does not use cookies to store information
                  about visitors' preferences, to record user-specific
                  information on which pages the user access or visit, or to
                  customize web page content based on visitors' browser type or
                  other information that the visitor sends via their browser.
                </p>

                <h2>Links to Other Sites</h2>
                <p>
                  MathSpeedUp.com may contain links to other websites. Please be
                  aware that we are not responsible for the content or privacy
                  practices of these other sites. We encourage our users to be
                  aware when they leave our site and to read the privacy
                  statements of any other site that collects personally
                  identifiable information.
                </p>

                <h2>Children's Information</h2>
                <p>
                  MathSpeedUp.com does not knowingly collect any personally
                  identifiable information from children under the age of 13. If
                  a parent or guardian believes that MathSpeedUp.com has in its
                  database the personally-identifiable information of a child
                  under the age of 13, please contact us immediately (using the
                  contact information provided below) and we will use our best
                  efforts to promptly remove such information from our records.
                </p>

                <h2>Updates to this Privacy Policy</h2>
                <p>
                  MathSpeedUp.com may update this Privacy Policy from time to
                  time. We will notify you of any changes by posting the new
                  Privacy Policy on this page.
                </p>

                <h2>Contact Us</h2>
                <p>
                  If you have any questions about this Privacy Policy, please
                  contact us at <strong>manishprajapat94@gmail.com</strong>.
                </p>

                <p>
                  By using our website, you hereby consent to our Privacy Policy
                  and agree to its Terms and Conditions.
                </p>
              </div>
              <div className="col-lg-2"></div>
            </div>
          </div>
        </section>
      </main>
    </>
  );
};

export default Policy;
